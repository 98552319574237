body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  position: relative;
  z-index: 0;
}

#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.backdrop{
  background-color: rgb(224, 136, 21);
  backdrop-filter: blur(100px);
  height: 100vh;
}

.flip{
  transform: scaleX(-1);
}.shiny-card {
  position: relative; /* Ensure the ::before element is positioned correctly */
  background-color: #f6601d; /* Set the background to orange */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Card shadow */
  overflow: hidden; /* Make sure the shimmer stays within the card */
  border-radius: 8px;
}

.shiny-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100px;
  width: 200%;
  height: 100%;
  background: -webkit-linear-gradient(top, transparent, rgba(244, 244, 244, 0.2), transparent);
  animation: shimmer 10s infinite; /* Set the shimmer animation to loop every 5 seconds */
}

@keyframes shimmer {
  0%, 100% {
    transform: translateY(-200%) rotate(30deg); /* Start from the top */
  }
  50% {
    transform: translateY(300%) rotate(30deg); /* Move down to the bottom */
  }
}
